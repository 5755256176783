<template>
  <div class="logo">
    <a href="https://onereach.ai/" class="logo__link">
      <img v-if="GlobalStore.isDarkMode" src="@/assets/logo-dark.svg" :alt="$t('logo.alt')" class="logo__img">
      <img v-else src="@/assets/logo.svg" :alt="$t('logo.alt')" class="logo__img">
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'

export default defineComponent({
  name: 'AppLogo',
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return { GlobalStore }
  }
})
</script>

<style scoped lang="scss">
.logo {
  position: absolute;
  top: 16px;
  left: 32px;
  height: 32px;

  @media (max-width: $tablet-width) {
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.logo__link {
  display: block;
}

.logo__img {
  display: block;
  width: 120px;
  height: 16px;

  @media (max-width: $tablet-width) {
    width: 160px;
    height: 21px;
  }
}
</style>
