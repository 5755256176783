import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-628372e1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "platform__title" }
const _hoisted_2 = { class: "platform__content" }
const _hoisted_3 = { class: "platform__description" }
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["platform", { active: _ctx.isActive }])
  }, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('platformMobile.title')), 1),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('platformMobile.description')), 1),
        _createElementVNode("img", {
          class: "platform__image",
          src: _ctx.appData.qrcode,
          alt: _ctx.$t('platformMobile.qrCode')
        }, null, 8, _hoisted_4)
      ])
    ], 512), [
      [_vShow, _ctx.isActive]
    ])
  ], 2))
}