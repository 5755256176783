import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a37fd920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "platform__title" }
const _hoisted_2 = { class: "platform__content" }
const _hoisted_3 = { class: "platform__description" }
const _hoisted_4 = { class: "platform__btn btn btn--primary" }
const _hoisted_5 = { class: "btn-text" }
const _hoisted_6 = { class: "platform__btn btn btn--secondary" }
const _hoisted_7 = { class: "btn-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.GlobalStore.isMacOS)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["platform", { active: _ctx.isActive }])
      }, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('platformMacos.title')), 1),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('platformMacos.description')), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('platformMacos.getApps')), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('platformMacos.addToApps')), 1)
          ])
        ], 512), [
          [_vShow, _ctx.isActive]
        ])
      ], 2))
    : _createCommentVNode("", true)
}