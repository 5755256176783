<template>
  <div class="error-404">
    <h3 class="error-404__icon">{{ $t('error404.number') }}</h3>
    <h3 class="error-404__title">{{ $t('error404.title') }}</h3>
    <p class="error-404__text">{{ $t('error404.text') }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppError404'
})
</script>

<style scoped lang="scss">
.error-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  height: auto !important;
  min-height: 100vh;
  box-sizing: border-box;
}

.error-404__icon {
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  font-size: 2rem;
  background-image: url('~@/assets/404.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  text-indent: -9999rem;
}

.error-404__title {
  margin: 0;
  font-size: 1.3rem;
  font-weight: bold;
  letter-spacing: -0.45px;
}

.error-404__text {
  margin: 1rem 0 0;
  text-align: center;
  color: $color-manatee;
}
</style>
