import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6622130b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mobile__step1"
}
const _hoisted_2 = { class: "mobile__description" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "btn-text" }
const _hoisted_5 = ["href"]
const _hoisted_6 = {
  class: "btn-text",
  "data-test-id": "open-in-browser-title"
}
const _hoisted_7 = {
  key: 1,
  class: "mobile__step2"
}
const _hoisted_8 = { class: "section-header" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "btn-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInfo = _resolveComponent("AppInfo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["wrapper", { 'android': _ctx.GlobalStore.isAndroidOs, 'iOS': _ctx.GlobalStore.isIosOs }])
  }, [
    _createVNode(_component_AppInfo, { appData: _ctx.appData }, null, 8, ["appData"]),
    (_ctx.currentStep === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('shareMobile.description')), 1),
          _createElementVNode("a", {
            href: _ctx.appData.fallbackLink,
            class: "mobile__btn mobile__btn-add btn btn--primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentStep = 2))
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('shareMobile.addToMyApps')), 1)
          ], 8, _hoisted_3),
          (_ctx.canOpenApp)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.openAppUrl,
                class: _normalizeClass(["mobile__btn mobile__btn-open btn", { 'btn--tertiery': _ctx.GlobalStore.isAndroidOs, 'btn--secondary': !_ctx.GlobalStore.isAndroidOs }]),
                "data-test-id": "open-in-browser-link-to-open"
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('shareMobile.openInBrowser')), 1)
              ], 10, _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t('shareMobile.noAppInstalled')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('shareMobile.step1')), 1),
          (_ctx.GlobalStore.isIosOs)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                _createElementVNode("a", {
                  class: "app-store",
                  href: "https://apps.apple.com/app/onereach-ai-apps/id1474221207",
                  target: "_blank",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.copyShareUrlToClipboard && _ctx.copyShareUrlToClipboard(...args)))
                }, _toDisplayString(_ctx.$t('shareMobile.downloadApp')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.GlobalStore.isAndroidOs)
            ? (_openBlock(), _createElementBlock("p", _hoisted_10, [
                _createElementVNode("a", {
                  class: "google-play",
                  href: "https://play.google.com/store/apps/details?id=ai.onereach.apps",
                  target: "_blank",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyShareUrlToClipboard && _ctx.copyShareUrlToClipboard(...args)))
                }, _toDisplayString(_ctx.$t('shareMobile.downloadApp')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('shareMobile.step2')), 1),
          _createElementVNode("a", {
            href: _ctx.appData.fallbackLink,
            class: "mobile__btn btn btn--primary"
          }, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('shareMobile.addToMyApps')), 1)
          ], 8, _hoisted_11)
        ]))
  ], 2))
}