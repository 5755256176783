import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68007f40"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "platform__title",
  "data-test-id": "web-platform-title"
}
const _hoisted_2 = { class: "platform__content" }
const _hoisted_3 = { class: "platform__description" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "btn-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["platform", { active: _ctx.isActive }])
  }, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('platformWeb.title')), 1),
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('platformWeb.description')), 1),
      _createElementVNode("a", {
        href: _ctx.openAppUrl,
        target: "_blank",
        class: "platform__btn btn btn--primary",
        "data-test-id": "web-platform-link-to-open"
      }, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('platformWeb.openApp')), 1)
      ], 8, _hoisted_4)
    ], 512), [
      [_vShow, _ctx.isActive]
    ])
  ], 2))
}