export interface IGlobalId {
  id: string
  domain: string
}

export const makeGlobalIdFromValue = (value: string): IGlobalId | undefined => {
  const elements = value.split('.')
  if (elements.length < 2) return
  const localId = elements.shift()
  if (localId === undefined) return
  return { id: localId, domain: elements.join('.') }
}

export const isGlobalId = (id: string): boolean => {
  return makeGlobalIdFromValue(id) !== undefined
}
