<template>
  <div class="platform" :class="{ active: isActive }">
    <h3 class="platform__title">{{ $t('platformMobile.title') }}</h3>
    <div class="platform__content" v-show="isActive">
      <div class="platform__description">
        <span>{{ $t('platformMobile.description') }}</span>
        <img class="platform__image" :src="appData.qrcode" :alt="$t('platformMobile.qrCode')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IApp } from '@/interfaces'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PlatformMobile',
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.platform__title::before {
  background-image: url('~@/assets/platforms/qrcode.svg');

  @media (prefers-color-scheme: dark) {
    background-image: url('~@/assets/platforms/qrcode-dark.svg');
  }
}
</style>
