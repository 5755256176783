import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f8c10a92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-info-wrapper" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "section-header" }
const _hoisted_5 = { class: "section-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInfo = _resolveComponent("AppInfo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppInfo, { appData: _ctx.appData }, null, 8, ["appData"]),
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('shareDesktop.chooseHowToRun')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.platforms, (platform) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(platform.componentName), {
        key: platform.componentName,
        appData: _ctx.appData,
        isActive: platform.isActive,
        onClick: ($event: any) => (_ctx.onPlatformClick(platform))
      }, null, 8, ["appData", "isActive", "onClick"]))
    }), 128)),
    (_ctx.showWhatIsTheDifferenceSection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('shareDesktop.whatIsTheDifference')), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('shareDesktop.description')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}