<template>
  <div v-if="GlobalStore.isMacOS" class="platform" :class="{ active: isActive }">
    <h3 class="platform__title">{{ $t('platformMacos.title') }}</h3>
    <div class="platform__content" v-show="isActive">
      <div class="platform__description">{{ $t('platformMacos.description') }}</div>
      <div class="platform__btn btn btn--primary"><span class="btn-text">{{ $t('platformMacos.getApps') }}</span></div>
      <div class="platform__btn btn btn--secondary"><span class="btn-text">{{ $t('platformMacos.addToApps') }}</span></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'

export default defineComponent({
  name: 'PlatformMacos',
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return { GlobalStore }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.platform__title::before {
  background-image: url('~@/assets/platforms/app-store.svg');
}
</style>
