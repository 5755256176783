<template>
  <div class="app-info-wrapper">
    <AppInfo :appData="appData"></AppInfo>
    <h2 class="section-header">{{ $t('shareDesktop.chooseHowToRun') }}</h2>
    <template v-for="platform in platforms" :key="platform.componentName">
      <component :is="platform.componentName"
        :appData="appData"
        :isActive="platform.isActive"
        @click="onPlatformClick(platform)"
      ></component>
    </template>
    <div v-if="showWhatIsTheDifferenceSection">
      <h2 class="section-header">{{ $t('shareDesktop.whatIsTheDifference') }}</h2>
      <p class="section-text">{{ $t('shareDesktop.description') }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, inject } from 'vue'
import AppInfo from '@/components/AppInfo.vue'
import PlatformBex from '@/components/PlatformBex.vue'
import PlatformMacos from '@/components/PlatformMacos.vue'
import PlatformWindows from '@/components/PlatformWindows.vue'
import PlatformMobile from '@/components/PlatformMobile.vue'
import PlatformWeb from '@/components/PlatformWeb.vue'
import { IApp, PLATFORM_TYPE, IPlatform } from '@/interfaces'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'
import { canOpenAppUsingUrl } from '@/apps/app-url'

export default defineComponent({
  name: 'ShareDesktop',
  components: {
    AppInfo,
    PlatformBex,
    PlatformMacos,
    PlatformWindows,
    PlatformMobile,
    PlatformWeb
  },
  props: {
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return {
      GlobalStore
    }
  },
  data () {
    return {
      platforms: [] as IPlatform[]
    }
  },
  computed: {
    activePlatform (): IPlatform {
      return this.platforms.find((platform: IPlatform) => platform.isActive) as IPlatform
    },
    showWhatIsTheDifferenceSection (): boolean {
      const displayedPlatformsList = this.platforms.filter((platform: IPlatform) => {
        if (platform.componentName === PLATFORM_TYPE.BEX) {
          return this.GlobalStore.isChromeExtensionCompatibleBrowser
        }
        return true
      })
      return displayedPlatformsList.length > 1
    }
  },
  methods: {
    onPlatformClick (platform: IPlatform): void {
      if (this.activePlatform === platform) {
        return
      }
      this.activePlatform.isActive = false
      platform.isActive = true
    }
  },
  created () {
    this.platforms = [
      // { componentName: PLATFORM_TYPE.BEX, isActive: this.GlobalStore.isChromeExtensionCompatibleBrowser },
      { componentName: PLATFORM_TYPE.MOBILE, isActive: false }
    ]
    if (canOpenAppUsingUrl(this.appData)) {
      this.platforms.push({ componentName: PLATFORM_TYPE.WEB, isActive: false })
    }
    if (this.GlobalStore.isWindowsOs) {
      this.platforms.unshift({ componentName: PLATFORM_TYPE.WINDOWS, isActive: false })
    }
    this.platforms[0].isActive = true
  }
})
</script>

<style scoped lang="scss">
.app-info-wrapper {
  position: relative;
  width: 600px;
  margin: 32px auto 0;
}

.section-header {
  margin: 32px 0 16px;
  font-size: 1.3rem;
  font-weight: 600;
}

.section-text {
  line-height: 1.5;
}
</style>

<style lang="scss">
.platform {
  margin-bottom: 16px;
  padding: 16px;
  background-color: $color-white;
  border: 1px solid $color-silver;
  border-radius: 4px;
  cursor: pointer;

  &.active {
    cursor: default;
    box-shadow: 0 0 5px 1px $color-silver;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.24);
  }

  @media (prefers-color-scheme: dark) {
    background-color: $color-shark;
  }
}
.platform__title {
  position: relative;
  margin: 0;
  padding: 6px 0 6px 46px;

  &::before {
    content: '';
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -16px;
    background-repeat: no-repeat;
    background-position: center;
    transform: translate3d(0,0,0);
  }
}
.platform__description {
  display: flex;
  margin: 10px 0 0;
  white-space: pre-line;
  line-height: 1.5;
}
.platform__image {
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  margin: -20px -10px -10px 0;
}
.platform__btn {
  margin: 12px 8px 0 0;
}
</style>
