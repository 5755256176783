import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/logo-dark.svg'
import _imports_1 from '@/assets/logo.svg'


const _withScopeId = n => (_pushScopeId("data-v-15c6503e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logo" }
const _hoisted_2 = {
  href: "https://onereach.ai/",
  class: "logo__link"
}
const _hoisted_3 = ["alt"]
const _hoisted_4 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      (_ctx.GlobalStore.isDarkMode)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _imports_0,
            alt: _ctx.$t('logo.alt'),
            class: "logo__img"
          }, null, 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _imports_1,
            alt: _ctx.$t('logo.alt'),
            class: "logo__img"
          }, null, 8, _hoisted_4))
    ])
  ]))
}