<template>
  <div v-if="GlobalStore.isChromeExtensionCompatibleBrowser" class="platform" :class="{ active: isActive }">
    <h3 class="platform__title">{{ $t('platformBex.title') }}</h3>
    <div class="platform__content" v-show="isActive">
      <div class="platform__description">{{ $t('platformBex.description') }}</div>

      <a v-if="!isBexInstalled" href="https://chrome.google.com/webstore/detail/onereachai-apps/gfnpjadehbepalbkacbdglejkhkcflgc?authuser=1" target="_blank" class="platform__btn btn btn--primary" @click="onGetBexClick"><span class="btn-text">{{ $t('platformBex.getBrowserExtension') }}</span></a>

      <a v-if="isBexInstalled && !isAppInstalled" :href="appData.fallbackLink" class="platform__btn btn btn--primary"><span class="btn-text">{{ $t('platformBex.addToBrowserExtension') }}</span></a>

      <a v-if="isAppInstalled" :href="appData.url" class="platform__btn btn btn--primary"><span class="btn-text">{{ $t('platformBex.openWithBrowserExtension') }}</span></a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType } from 'vue'
import { IApp } from '@/interfaces'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'

const IS_APP_INSTALLED_DATA_ATTR_NAME = 'isAppInstalled'
const BEX_ADD_APP_DATA_ATTR_NAME = 'addAppToBex'

export default defineComponent({
  name: 'PlatfromBex',
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)
    return { GlobalStore }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  data () {
    return {
      isBexInstalled: false,
      isAppInstalled: false
    }
  },
  methods: {
    onGetBexClick () {
      document.documentElement.dataset[BEX_ADD_APP_DATA_ATTR_NAME] = this.appData.id
    },
    init () {
      this.isBexInstalled = document.documentElement.dataset[IS_APP_INSTALLED_DATA_ATTR_NAME] !== undefined
      this.isAppInstalled = document.documentElement.dataset[IS_APP_INSTALLED_DATA_ATTR_NAME] === 'true'
    }
  },
  mounted () {
    setTimeout(this.init, 50)
  }
})
</script>

<style lang="scss" scoped>
.platform__title::before {
  background-image: url('~@/assets/platforms/chrome-store.svg');
}
</style>
