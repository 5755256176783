<template>
  <div v-if="GlobalStore.isWindowsOs" class="platform" :class="{ active: isActive }">
    <h3 class="platform__title">{{ $t('platformWindows.title') }}</h3>
    <div class="platform__content" v-show="isActive">
      <div class="platform__description">{{ $t('platformWindows.description') }}</div>
      <a :href="appData.fallbackLink" class="platform__btn btn btn--primary" @click="addToApps"><span class="btn-text">{{ $t('platformWindows.addToApps') }}</span></a>
      <div :style="{display: `${showInstall ? 'block' : 'none'}`}" @click="copyAppUrl">
        <br/>
        <span class="">{{ $t('platformWindows.notInstalledYet') }}</span> <br/>
        <ms-store-badge
          productid="9N4KL7JFS0V9"
          window-mode="full"
          theme="auto"
          language="en" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, inject, ref } from 'vue'
import { GlobalStore as GlobalStoreKey } from '@/symbolKeys'
import { IApp } from '@/interfaces'

export default defineComponent({
  name: 'PlatformWindows',
  setup () {
    const GlobalStore = inject(GlobalStoreKey, {} as Record<string, boolean>)

    return { GlobalStore, showInstall: ref(false) }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    appData: {
      type: Object as PropType<IApp>,
      required: true
    }
  },
  methods: {
    addToApps () {
      this.showInstall = true
      this.copyAppUrl()
    },
    copyAppUrl () {
      const temp = document.createElement('textarea')
      temp.value = this.appData.fallbackLink
      document.body.appendChild(temp)
      temp.select()
      temp.setSelectionRange(0, 99999)
      document.execCommand('copy')
      document.body.removeChild(temp)
    }
  }

})
</script>

<style lang="scss" scoped>
.platform__title::before {
  background-image: url('~@/assets/platforms/windows-store.svg');
}

ms-store-badge::part(img) {
    margin-top: 10px;
    max-height: 40px;
}
</style>
