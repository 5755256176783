import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-263b127c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-info" }
const _hoisted_2 = { class: "app-info__text" }
const _hoisted_3 = {
  class: "app-info__name",
  "data-test-id": "app-name"
}
const _hoisted_4 = { class: "app-info__description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "app-info__img",
      style: _normalizeStyle({
      backgroundColor: `#${_ctx.appData.color}`,
      backgroundImage: `${_ctx.appData.avatar ? 'url(\'' + _ctx.appData.avatar + '\')' : 'none'}`
    })
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.appData.name), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.appData.description), 1)
    ])
  ]))
}