import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43b1c003"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-404" }
const _hoisted_2 = { class: "error-404__icon" }
const _hoisted_3 = { class: "error-404__title" }
const _hoisted_4 = { class: "error-404__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t('error404.number')), 1),
    _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('error404.title')), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('error404.text')), 1)
  ]))
}