import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d81f5378"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "platform__title" }
const _hoisted_2 = { class: "platform__content" }
const _hoisted_3 = { class: "platform__description" }
const _hoisted_4 = { class: "btn-text" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "btn-text" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "btn-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.GlobalStore.isChromeExtensionCompatibleBrowser)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["platform", { active: _ctx.isActive }])
      }, [
        _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('platformBex.title')), 1),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('platformBex.description')), 1),
          (!_ctx.isBexInstalled)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: "https://chrome.google.com/webstore/detail/onereachai-apps/gfnpjadehbepalbkacbdglejkhkcflgc?authuser=1",
                target: "_blank",
                class: "platform__btn btn btn--primary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onGetBexClick && _ctx.onGetBexClick(...args)))
              }, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('platformBex.getBrowserExtension')), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isBexInstalled && !_ctx.isAppInstalled)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                href: _ctx.appData.fallbackLink,
                class: "platform__btn btn btn--primary"
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platformBex.addToBrowserExtension')), 1)
              ], 8, _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.isAppInstalled)
            ? (_openBlock(), _createElementBlock("a", {
                key: 2,
                href: _ctx.appData.url,
                class: "platform__btn btn btn--primary"
              }, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('platformBex.openWithBrowserExtension')), 1)
              ], 8, _hoisted_7))
            : _createCommentVNode("", true)
        ], 512), [
          [_vShow, _ctx.isActive]
        ])
      ], 2))
    : _createCommentVNode("", true)
}