import { IApp, APP_ACTION_TYPE, IAppAction } from '@/interfaces'
import { isGlobalId, makeGlobalIdFromValue } from './global-id'

const makeAppUrlByGlobalAppIdValue = (id: string): string | undefined => {
  const globalId = makeGlobalIdFromValue(id)
  if (globalId === undefined) return
  return `https://${globalId.domain}/browser/index.html#/app/${id}`
}

const makeOpenAppUrlFromAction = (action: IAppAction, app: IApp): string | undefined => {
  if (action.type === APP_ACTION_TYPE.VOICE) {
    return makeAppUrlByGlobalAppIdValue(app.id)
  }
  return action.url
}

const hasSipUrl = (app: IApp): boolean => {
  return app?.url !== undefined && app.url.startsWith('sip:')
}

const makeOpenAppUrlFromAppLegacyUrl = (app: IApp): string | undefined => {
  return hasSipUrl(app) ? makeAppUrlByGlobalAppIdValue(app.id) : app?.url
}

export const makeOpenAppUrl = (app: IApp): string | undefined => {
  if (app.actions.length > 0) {
    return makeOpenAppUrlFromAction(app.actions[0], app)
  } else {
    return makeOpenAppUrlFromAppLegacyUrl(app)
  }
}

export const canOpenAppUsingUrl = (app: IApp): boolean => {
  return isGlobalId(app.id) && (app.actions.length > 0 || app?.url !== undefined)
}
